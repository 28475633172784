import {
  AppRoleEnum,
  FeatureNameEnum,
  PlaceNode,
  PlaceNodeTypeEnum,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import { List, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import {
  ModalDeleteButtonColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const TableBody = styled<any>(List)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
  word-break: break-word;
`

const UsersHeaderContainer = styled(Row)`
  overflow-x: auto;
  justify-content: start;
  margin: 15px 0 15px 15px;
`

const UsersHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  padding-right: 15px;
`

const UsersHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
  word-break: break-word;
`

const COLUMN_WIDTHS = ['20%', '20%', '30%', '30%']
const LEGACY_COLUMN_WIDTHS = ['33%', '33%', '33%']

const formatPlaceNodeNames = (
  userPlaceNodes: PlaceNode[] | undefined,
  allPlaceNodes: PlaceNode[],
) => {
  return userPlaceNodes && userPlaceNodes.length > 0
    ? userPlaceNodes
        .map(node => {
          if (node.type === PlaceNodeTypeEnum.Place) {
            return (
              allPlaceNodes.find(
                placeNode => placeNode.place?.placeNodeId === node.id,
              )?.place?.name || ''
            )
          } else {
            return (
              allPlaceNodes.find(
                placeNode => placeNode.placeGroup?.placeNodeId === node.id,
              )?.placeGroup?.name || ''
            )
          }
        })
        .join(' ')
    : ''
}

const formatUserGroupNames = (userGroups: UserGroup[] | undefined) => {
  return userGroups && userGroups.length > 0
    ? userGroups.map((userGroup: UserGroup) => userGroup.name).join(' ')
    : ''
}

interface OwnProps {
  users: User[]
}

const UserList: React.FC<OwnProps> = (props: OwnProps) => {
  const dispatch = useDispatch()
  const canUseManageUserOnRump =
    useFeature(FeatureNameEnum.MANAGE_USER_ON_RUMP).canUse === 'yes'

  const {
    users: {
      userDeleteValidateLoading,
      userDeleteValidateResult,
      userDeleteValidateErrorMessage,
    },
  } = useSelector((state: RootState) => state.usersState)

  const {
    placeNodes: { nodes },
  } = useSelector((state: RootState) => state.placesState)

  const [activeUserUUID, setActiveUserUUID] = useState<string>()
  const [activeDeleteUser, setActiveDeleteUser] = useState<User | undefined>(
    undefined,
  )

  const openEditUser = (user: User) => {
    usersOperations.updateActiveUserId(dispatch, user.uuid ?? '')
    usersOperations.changeIsShowEditUserDrawer(dispatch, true)
  }

  const validateDeleteUser = (uuid: string) => {
    usersOperations.validateDeleteUser(dispatch, uuid)
  }

  useEffect(() => {
    if (userDeleteValidateResult !== 'canBeDeleted') return

    usersOperations.resetValidateDeleteUser(dispatch)

    if (activeDeleteUser === undefined) {
      return
    }

    Modal.confirm({
      onOk: () => {
        usersOperations.deleteUser(dispatch, activeDeleteUser.uuid ?? '')
      },
      title: `「${activeDeleteUser.name}」の削除を実行しますがよろしいですか？`,
      okText: '削除',
      cancelText: 'キャンセル',
      afterClose: () => {
        setActiveDeleteUser(undefined)
      },
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
    })
  }, [activeDeleteUser, dispatch, userDeleteValidateResult])

  useEffect(() => {
    if (userDeleteValidateResult !== 'canNotDeleted') return
    const splitErrMsg = userDeleteValidateErrorMessage.split('\n')
    usersOperations.resetValidateDeleteUser(dispatch)

    if (activeDeleteUser === undefined) {
      return
    }

    Modal.error({
      onOk: () => usersOperations.resetValidateDeleteUser(dispatch),
      title: (
        <div>
          <b>「{activeDeleteUser.name}」の削除が出来ません</b>
        </div>
      ),
      width: 470,
      content: (
        <div>
          <p>
            承認待ちのレポート、もしくは承認フロー内に削除対象のユーザーが含まれる場合、ユーザーの削除が出来ません。
          </p>
          <p>
            <b>削除が出来ない理由</b>
          </p>
          {splitErrMsg.map((msg, i) => {
            return <div key={i}>・{msg}</div>
          })}
        </div>
      ),
      okText: '閉じる',
      afterClose: () => {
        setActiveDeleteUser(undefined)
      },
    })
  }, [
    activeDeleteUser,
    dispatch,
    userDeleteValidateErrorMessage,
    userDeleteValidateResult,
  ])

  return (
    <>
      <LoadingOverlay
        spinning={userDeleteValidateLoading}
        size="large"
        render={
          <UsersHeaderContainer justify="center">
            <UsersHeaderWrap>
              <UsersHeader>
                {canUseManageUserOnRump ? (
                  <>
                    <HeaderRow theme={{ width: COLUMN_WIDTHS[0] }}>
                      社員番号
                    </HeaderRow>
                    <HeaderRow theme={{ width: COLUMN_WIDTHS[1] }}>
                      ユーザー名
                    </HeaderRow>
                    <HeaderRow theme={{ width: COLUMN_WIDTHS[2] }}>
                      ユーザーグループ名
                    </HeaderRow>
                    <HeaderRow theme={{ width: COLUMN_WIDTHS[3] }}>
                      現場名
                    </HeaderRow>
                  </>
                ) : (
                  <>
                    <HeaderRow theme={{ width: LEGACY_COLUMN_WIDTHS[0] }}>
                      ユーザー名
                    </HeaderRow>
                    <HeaderRow theme={{ width: LEGACY_COLUMN_WIDTHS[1] }}>
                      ユーザーグループ名
                    </HeaderRow>
                    <HeaderRow theme={{ width: LEGACY_COLUMN_WIDTHS[2] }}>
                      現場名
                    </HeaderRow>
                  </>
                )}
              </UsersHeader>
              <TableBody
                itemLayout="horizontal"
                dataSource={props.users}
                renderItem={(user: User) => (
                  <TableRowWrap
                    onMouseEnter={() => setActiveUserUUID(user.uuid)}
                    onMouseLeave={() => setActiveUserUUID(undefined)}
                  >
                    {canUseManageUserOnRump ? (
                      <>
                        <TableRow theme={{ width: COLUMN_WIDTHS[0] }}>
                          {user.rumpCustomerMemberId}
                        </TableRow>
                        <TableRow theme={{ width: COLUMN_WIDTHS[1] }}>
                          {user.name}
                        </TableRow>
                        <TableRow theme={{ width: COLUMN_WIDTHS[2] }}>
                          {formatUserGroupNames(user.userGroups)}
                        </TableRow>
                        <TableRow theme={{ width: COLUMN_WIDTHS[3] }}>
                          {formatPlaceNodeNames(user.placeNodes, nodes)}
                        </TableRow>
                        {activeUserUUID === user.uuid && (
                          <ButtonGroupRow>
                            <RowActionButton
                              onClick={() => openEditUser(user)}
                              type="edit"
                            />
                          </ButtonGroupRow>
                        )}
                      </>
                    ) : (
                      <>
                        <TableRow theme={{ width: LEGACY_COLUMN_WIDTHS[0] }}>
                          {user.name}
                        </TableRow>
                        <TableRow theme={{ width: LEGACY_COLUMN_WIDTHS[1] }}>
                          {formatUserGroupNames(user.userGroups)}
                        </TableRow>
                        <TableRow theme={{ width: LEGACY_COLUMN_WIDTHS[2] }}>
                          {formatPlaceNodeNames(user.placeNodes, nodes)}
                        </TableRow>
                        {activeUserUUID === user.uuid && (
                          <ButtonGroupRow>
                            <RowActionButton
                              onClick={() => openEditUser(user)}
                              type="edit"
                            />
                            {user.role?.role !== AppRoleEnum.OWNER && (
                              <RowActionButton
                                onClick={() => {
                                  setActiveDeleteUser(user)
                                  validateDeleteUser(user.uuid ?? '')
                                }}
                                type="delete"
                              />
                            )}
                          </ButtonGroupRow>
                        )}
                      </>
                    )}
                  </TableRowWrap>
                )}
              />
            </UsersHeaderWrap>
          </UsersHeaderContainer>
        }
      />
    </>
  )
}

export default UserList
