import { ExportOutlined, InfoCircleFilled } from '@ant-design/icons'
import {
  FeatureNameEnum,
  GetUsersRequest,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Layout, Row, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { getKaminashiIDBasePath } from 'src/config'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { useQueryLoginUserContext } from 'src/features/tanStackQuery/commonQueries/useQueryLoginUserContext'
import placesOperations from 'src/state/ducks/places/operations'
import usersOperations from 'src/state/ducks/users/operations'
import { GetUsersFilter } from 'src/state/ducks/users/types'
import { RootState } from 'src/state/store'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import Loading from '../../components/loading/Loading'
import { useListFilter } from '../../hooks/filter/useListFilter'
import { useFeature } from '../featureFlags/useFeatureFlags'
import { Yellow0, Yellow9 } from '../theme/KdsThemeColor'
import UserList from './UserList'
import {
  AddUserButton,
  ContentWrapper,
  ListContainer,
  ListHeader,
  UserLimitMessage,
  UsersPagination,
} from './Users.styled'
import UsersFilterDropDown from './UsersFilterDropDown'
import EditUser from './editUser/EditUser'
import EditUserLegacy from './editUser/EditUserLegacy'

const { Text } = Typography

const Users: FC = () => {
  const dispatch = useDispatch()

  const { filters, setListFilter } = useListFilter()
  const [userFilters, setUserFilter] = useState<GetUsersFilter>()

  const { canUse: manageUserOnRump } = useFeature(
    FeatureNameEnum.MANAGE_USER_ON_RUMP,
  )
  const { data: loginUserContext } = useQueryLoginUserContext()
  const linkToKaminashiIDUserListPage = new URL(
    `/realms/${loginUserContext?.rumpRealmId ?? ''}/members`,
    getKaminashiIDBasePath(),
  ).toString()

  const {
    places: { places },
    placeGroups: { nodes: placeGroups = [] },
    placeNodes: { isLoading: isLoadingPlaceNode },
  } = useSelector((state: RootState) => state.placesState)

  const setPlaces = places ? [...places] : []
  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(placeGroups),
    ...setPlaces,
  ])
  const {
    user: { user },
    users: {
      users,
      isShowEditUserDrawer,
      updatedUsers,
      count,
      request,
      filter,
      isLoading: isLoadingUser,
    },
    userGroups: { isLoading: isLoadingUserGroup },
  } = useSelector((state: RootState) => state.usersState)

  const userLimit = user?.company?.userLimit || 0

  const isLoading = isLoadingUser || isLoadingUserGroup || isLoadingPlaceNode

  useEffect(() => {
    getPlaces()
    getPlaceNodes()
    getUserGroups()

    if (filters && filters.users) {
      const usersFilter = filters?.users
      updatePagination(
        usersFilter?.pagination?.limit ?? 25,
        usersFilter?.pagination?.offset ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      users: {
        pagination: request,
        filter: filters?.users?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  // フィルター更新を検知
  useEffect(() => {
    // UsersFilterDropDown の useEffect 側で setUserFilter が必ず設定されるので undefined のときはスキップする
    if (userFilters) {
      getUsers(request, userFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilters])

  // ユーザー更新があった際に再更新
  useEffect(() => {
    if (!isLoadingUser && updatedUsers) {
      getUsers(request, userFilters)
      changeUpdatedUsersFlag(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser])

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(request.limit, request.offset, count)

  // 閉じるボタン or Drawer外のクリックしたとき
  const onClose = () => {
    updateSegmentIndex(0)
    changeIsShowEditUserDrawer(false)
  }

  const getUsers = (request: GetUsersRequest, filter?: GetUsersFilter) => {
    usersOperations.getUsers(dispatch, request, filter)
  }
  const changeUsersPage = (
    page: number,
    pageSize?: number,
    filter?: GetUsersFilter,
  ) => {
    usersOperations.changeUsersPage(dispatch, page, pageSize, filter)
  }
  const changeUsersSize = (pageSize: number, filter?: GetUsersFilter) => {
    usersOperations.changeUsersSize(dispatch, pageSize, filter)
  }
  const updatePagination = (limit: number, offset: number) => {
    usersOperations.updatePagination(dispatch, limit, offset)
  }
  const getUserGroups = () => {
    usersOperations.getUserGroups(dispatch, {})
  }
  const getPlaces = () => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
  }
  const getPlaceNodes = () => {
    placesOperations.getPlaceNodes(dispatch)
  }
  const updateSegmentIndex = (index: number) => {
    usersOperations.updateSegmentIndex(dispatch, index)
  }
  const changeIsShowEditUserDrawer = (isShow: boolean) => {
    usersOperations.changeIsShowEditUserDrawer(dispatch, isShow)
  }
  const changeUpdatedUsersFlag = (edited: boolean) => {
    usersOperations.changeUpdatedUsersFlag(dispatch, edited)
  }
  const openAddUser = () => {
    usersOperations.updateActiveUserId(dispatch, '')
    usersOperations.changeIsShowEditUserDrawer(dispatch, true)
  }
  // 現在選択中のユーザーの uuid。新規追加フォームが表示されている場合は空文字列が入る
  const activeUserId = useSelector(
    ({ usersState }: RootState) => usersState.users.activeUserId,
  )

  const renderUserList = () => {
    const isUserLimitExceeded = userLimit <= users.length

    return (
      <ListContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px 24px',
            backgroundColor: Yellow0,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <InfoCircleFilled style={{ color: Yellow9, fontSize: '21px' }} />
            <Text
              style={{ color: Yellow9, fontSize: '16px', fontWeight: 'bold' }}
            >
              右のボタンからユーザー追加の相談が可能です。
            </Text>
          </div>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://kaminashi.svy.ooo/ng/answers/d0365de9f3b210f1a43b8153b5d932/"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
          >
            <Button type="primary" icon={<ExportOutlined />}>
              見積もりを相談する
            </Button>
          </a>
        </div>
        <ListHeader justify="space-between">
          <UsersFilterDropDown
            places={places}
            placeNodes={placeNodes}
            updatedUsers={updatedUsers}
            isLoadingUser={isLoadingUser}
            request={request}
            filters={filters}
            setUserFilter={setUserFilter}
          />
          <div>
            {manageUserOnRump !== 'yes' && (
              // ユーザー作成ドロワーを開くボタン
              <AddUserButton
                type="primary"
                disabled={isUserLimitExceeded}
                onClick={() => openAddUser()}
              >
                ユーザーを追加
              </AddUserButton>
            )}
            {manageUserOnRump === 'yes' &&
              loginUserContext?.isRumpRealmAdmin && (
                // カミナシIDのユーザー一覧ページへのリンクボタン
                <AddUserButton
                  type="primary"
                  icon={<ExportOutlined />}
                  href={linkToKaminashiIDUserListPage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ユーザーを追加
                </AddUserButton>
              )}
            {isUserLimitExceeded && (
              <Row justify="end">
                <UserLimitMessage type="danger">
                  ユーザー登録数が上限に達しました
                </UserLimitMessage>
              </Row>
            )}
          </div>
        </ListHeader>
        {!isLoading && <UserList users={users} />}
        {isLoading && <Loading />}
      </ListContainer>
    )
  }

  return (
    <Layout>
      <Header>
        <HeaderTitle title="ユーザー" />
      </Header>
      <ContentWrapper>{renderUserList()}</ContentWrapper>
      <Drawer
        placement="right"
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        destroyOnClose={true}
        open={isShowEditUserDrawer}
        width={600}
        drawerStyle={{ padding: 0 }}
      >
        {manageUserOnRump === 'yes' && activeUserId !== '' ? (
          <EditUser />
        ) : (
          <EditUserLegacy />
        )}
      </Drawer>
      <UsersPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={request.limit ?? 25}
        defaultCurrent={1}
        total={count}
        onChange={(pageNum, pageSize) =>
          changeUsersPage(pageNum, pageSize, filter)
        }
        onShowSizeChange={(_, size) => changeUsersSize(size, filter)}
      />
    </Layout>
  )
}

export default withRouter(Users)
